<template>
  <div class="password">
    <div class="public-screen middle-box animated fadeIn">
      <img class="logo" src="@/assets/images/prologistics_logo.png" alt="Logo"/>
      <h2 class="m-t-xl m-b-md">
        {{ !success ? $t("common.enter_new_password") : $t("driver.password.header.successfully_changed") }}</h2>

      <b-form v-if="!success" @submit.prevent="onSubmit">
        <generic-input
            label="common.new_password"
            v-model="password"
            type="password"
            required
        ></generic-input>

        <generic-input
            label="common.password_confirmation"
            v-model="password_confirmation"
            type="password"
            required
        ></generic-input>

        <b-button class="m-b block full-width" type="submit" variant="primary">
          {{ $t('button.save') }}
        </b-button>
      </b-form>

      <div v-else class="m-t-lg">
        <p>{{ $t("driver.password.content.successfully_changed") }}</p>
        <p>{{ $t("driver.password.footer.your_team") }}</p>
      </div>

      <div class="text-center">
        <hr>
        <template v-for="(link, index) in linkList">
          <span v-if="index > 0"> | </span>
          <router-link :to="link.to" target="_blank">{{ $t(link.name) }}</router-link>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {updatePasswordByResetToken} from "@/services/drivers";
import GenericInput from "@/components/generic_input.vue";

export default {
  components: {GenericInput},
  data() {
    return {
      success: false,
      password: '',
      password_confirmation: '',
      linkList: [
        {
          to: {name: "driver_privacy"},
          name: "common.privacy_policy",
        },
        {
          to: {name: "driver_terms"},
          name: "common.terms_of_use"
        },
        {
          to: {name: "driver_imprint"},
          name: "common.imprint"
        }
      ]
    }
  },
  methods: {
    onSubmit(event) {
      updatePasswordByResetToken(this.$route.query.reset_password_token, this.password, this.password_confirmation).then(response => {
        this.success = true
      })
    },
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 15em;
  margin-top: 40px;
  margin-bottom: 40px;
}

label {
  color: #757575;
}

h2 {
  color: #222222;
  font-weight: bold;
}
</style>